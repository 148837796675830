import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { useFormik} from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";
import Select from "react-select";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle,
    ModalFooter
} from "reactstrap";


const ClientsAdhocServicesModal = ({clientID, clientName, serviceID, show, onCloseModal}) =>  {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [isEdit, setIsEdit] = useState(false);
    const [roster, setRoster] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ModalLoading, setModalLoading] = useState(false);

    const [ServicesListArray, setServicesListArray] = useState([]);
    const [SuppliersListArray, setSuppliersListArray] = useState([]);
    const [EmailsListArray, setEmailsListArray] = useState([]);
    const [emailNotify, setEmailNotify] = useState(false);

    //form fields
    const [ServiceDate, setServiceDate] = useState('');
    const [Services, setServices] = useState([]);
    const [Suppliers, setSuppliers] = useState([]);
    const [Hours, setHours] = useState([]);
    const [Emails, setEmails] = useState([])
    const [SupplierMobile, setSupplierMobile] = useState('')

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {

        },
        validationSchema: Yup.object({
           
        }),
        onSubmit: values => {

            if(ServiceDate == '' ) {
                toast.error('Please provide service date', { autoClose: 5000 })
                return;
            }

            if(Services.length == 0 ) {
                toast.error('Please select service details', { autoClose: 5000 })
                return;
            }

            if(Suppliers.length == 0 ) {
                toast.error('Please select supplier details', { autoClose: 5000 })
                return;
            }

            if( Hours == '' ) {
                toast.error('Please enter total number of hours', { autoClose: 5000 })
                return;
            }

            if( emailNotify ) {
                if( Emails.length == 0 ) {
                    toast.error('Please select emails you want to send report!', { autoClose: 5000 })
                    return;
                }
            }

            if( !isEdit ) {

                const formData = {
                    user_id: clientID,
                    service_date : ServiceDate,
                    services: Services,
                    suppliers: Suppliers,
                    hours: Hours,
                    email_notify: emailNotify ? 1 : 0,
                    emails: Emails
                };
        
                console.log( formData);
                // add data
                setModalLoading(true);
                axios.post(APP_URL+ACTION_URL,{'data_for':'add_client_adhoc_service','formData': formData,'user': loggedUser})
                    .then(res => {
                    setModalLoading(false);
                    
                    const fetchedData = res.data;
                    if( fetchedData.status == 'success' ) {
                        toast.success(fetchedData.html, { autoClose: 5000 })
                        setFormDefaultValues()
                        onCloseModal('reload')
                    }
            
                    if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.html, { autoClose: 5000 });
                    }
        
                });

            } else {

                const formData = {
                    user_id: clientID,
                    service_id: serviceID,
                    service_date : ServiceDate,
                    services: Services,
                    suppliers: Suppliers,
                    hours: Hours,
                    email_notify: emailNotify ? 1 : 0,
                    emails: Emails
                };
        
                console.log( formData);

                // add data
                setModalLoading(true);
                axios.post(APP_URL+ACTION_URL,{'data_for':'edit_client_adhoc_service','formData': formData,'user': loggedUser})
                    .then(res => {
                    setModalLoading(false);
                    
                    const fetchedData = res.data;
                    if( fetchedData.status == 'success' ) {
                        toast.success(fetchedData.html, { autoClose: 5000 });   
                        setFormDefaultValues(); 
                        setIsEdit(false);
                        onCloseModal('reload')
                    }
            
                    if( fetchedData.status == 'error' ) {
                        toast.error(fetchedData.html, { autoClose: 5000 });
                    }
        
                });

            }
        },
    })

    const setFormDefaultValues = () => {
        setServiceDate('')
        setServices([])
        setSuppliers([])
        setEmails([])
        setEmailNotify(false)
        setHours('')
        setSupplierMobile('')
    }

    useEffect(() => {

        if( show ) {

            setFormDefaultValues()

            const formData = {
                uid: clientID,
                serviceID: serviceID 
            }
            setModalLoading(true)
            
            axios.post(APP_URL+ACTION_URL,{'data_for':'get_client_adhoc_service_details','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {

                    
                    setServicesListArray(fetchedData.servicesList)
                    setSuppliersListArray(fetchedData.suppliersList)
                    setEmailsListArray(fetchedData.emailsList)

                    if( serviceID ) {

                        setIsEdit(true);
                        setServiceDate(fetchedData.clientServiceDetails.date_of_service)
                        setHours(fetchedData.clientServiceDetails.hours)
                        setServices(fetchedData.clientService)
                        setSuppliers(fetchedData.clientSupplier)
                        setEmailNotify(fetchedData.clientServiceDetails.email_option == 1 ? true : false)
                        setEmails(fetchedData.clientEmail)
                        setSupplierMobile(fetchedData.supplierMobile)

                    }


                }

                if( fetchedData.status == 'error' ) {
                    // setType('add');
                    // setPlanID('');
                    // toast.error(fetchedData.error, { autoClose: 5000 });
                }

            })

        }
    },[show])

    const handleSubmitBtn = () => {
        validation.handleSubmit();
    }

    return (
        <>

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable  s>
            <ModalHeader toggle={onCloseModal} tag="h4">
                Adhoc Services{ ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col md={12}>
                    <Form>

                    <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Service Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <Flatpickr
                                    name="create_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setServiceDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.service_date || ServiceDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Services
                            </Label>
                            <Col sm={9}>
                                <Select
                                    
                                    value={Services}
                                    name="language_spoken"
                                    options={ServicesListArray}
                                    onChange={(e) => setServices(e)}
                                    className="select2-selection"
                                    // defaultValue={Services}
                                    styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Suppliers
                            </Label>
                            <Col sm={9}>
                                <Select
                                    value={Suppliers}
                                    name="language_spoken"
                                    options={SuppliersListArray}
                                    onChange={(e) => setSuppliers(e)}
                                    className="select2-selection"
                                    // defaultValue={Suppliers}
                                    styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        { serviceID != '' &&

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Supplier contact no.
                            </Label>
                            <Col sm={9}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={SupplierMobile}
                                        disabled
                                    />
                            </Col>
                        </Row>
                        
                        }

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Hours
                            </Label>
                            <Col sm={9}>
                                    <Input
                                        type="number"
                                        name="hours"
                                        className="form-control"
                                        onChange={(e)=> setHours(e.target.value)}
                                        value={Hours}
                                        step={0.5}
                                        placeholder="Enter Hours"
                                        
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Email Notificaiton</Label>
                                    &nbsp;&nbsp;<Input
                                        type="checkbox"
                                        onChange={() => setEmailNotify(!emailNotify)}
                                        checked={emailNotify ? true : false}
                                        value={1}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Select Emails
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    value={Emails}
                                    name="emails"
                                    options={EmailsListArray}
                                    onChange={(e) => setEmails(e)}
                                    className="select2-selection"
                                    // defaultValue={Emails}
                                    isDisabled={!emailNotify}
                                    styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>
                        
                    </Form>
                    </Col>
                    {/* <Col md={4}>
                        <CardTitle>Activity Log</CardTitle>

                        <div className="order_notes_div">
            
                            <ul className="order_notes">
                                { activityLog.map(activity => (
                                <li key={activity.id} rel={activity.id} className="note">
                                    <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                    <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                    <strong>
                                    {activity.activity_by}
                                    </strong>
                                    </abbr></p>
                                </li>
                                ))}
                            </ul>
                        
                        </div>

                    </Col> */}
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <button type="btn" disabled={ModalLoading} onClick={handleSubmitBtn} className="btn btn-primary w-md">
                    { isEdit ? 'Update' : 'Add' }
                    </button>
                </div>
            </ModalFooter>
        
    </Modal>

        </> 
    )
}

ClientsAdhocServicesModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any,
    handleSubmitBtn: PropTypes.func
}

export default ClientsAdhocServicesModal;