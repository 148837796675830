import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map, values } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, COORDINATETEAM_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {Name, FeedStatus } from "../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
import CoordinationStaffScheduleModal from "components/Forms/CoordinationStaffScheduleModal"


function CoordinateTeamSchedule() {
  //meta title
  document.title="Coordinate Team Schedule | Tacveiha Panel";
  const navigate = useNavigate();
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  if( loggedUser.role != 'admin' && loggedUser.role != 'sub_admin' && loggedUser.role != 'subadmin') {
    navigate('/restricted')
  }
  
  const [modal, setModal] = useState(false);
  const [staffScheduleModal, setStaffScheduleModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [editID, setEditID] = useState('')
  const [events, setEvents] = useState([]);
  const [id, setID] = useState('');
  const [RosteredDay, setRosteredDay] = useState('')
  const [RosteredStaffsArray, setRosteredStaffsArray] = useState({label:'Select staff', value:'0'})
  const [RosteredStaffs, setRosteredStaffs] = useState([])
  const [RosterDay, setRosterDay] = useState('')
  const[CalanderStartDate, setCalanderStartDate] = useState('')
  const[CalanderEndDate, setCalanderEndDate] = useState('')


  const[ModalLoading, setModalLoading] = useState(false);

  const [roster, setRoster] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        rostered_day: RosteredDay || "",
        rostered_staff : RosteredStaffs|| "",
        roster_day : RosteredDay || "",
    },
    validationSchema: Yup.object({
        // service_name: Yup.string().required("Please Enter Service Name"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
            event_id: editID,
            rostered_day : RosteredDay,
            rostered_staff: RosteredStaffs,
            roster_day: RosterDay,
        };

        // console.log(updateUser);

        // update user
         setModalLoading(true);

         axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for': 'coordination_staff_schedule_edit','formData': updateUser,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                
                toast.success(fetchedData.html, { autoClose: 5000 });
                setRosteredStaffs([])
                setRosteredStaffsArray([])
                setRosteredDay('');
                setRosterDay('')
                loadStaffSchedule()
                toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      } else {
        const newData = {
            rostered_day : RosteredDay,
            rostered_staff: RosteredStaffs,
            roster_day: RosterDay,
        };

         //add staff leave
         setModalLoading(true);

         axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for': 'coordination_staff_schedule_add','formData': newData,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {

                toast.success(fetchedData.html, { autoClose: 5000 });
                setRosteredStaffs([])
                setRosteredStaffsArray([])
                setRosteredDay('')
                setRosterDay('')
                loadStaffSchedule()
                toggle()
                
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }

           })
      }
    },
  })


  const [isLoading, setLoading] = useState(false);
  

  const loadStaffSchedule = () => {

    const formData = {
      start_date: CalanderStartDate,
      end_date: CalanderEndDate
    }
    

    setModalLoading(true);
    // setModalLoading(false);
    axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for':'get_coordination_staff_calander_list','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;
        setEvents(fetchedData);
        // console.log(events);
    })

  }


  useEffect(() => {
    // loadStaffSchedule()
  },[])

  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      // setRoster([]);
    } else {
        setModal(true);
    }
  }

  const handleMonthChange = (payload) => {

    if( CalanderStartDate != payload.startStr ) {
      setCalanderStartDate(payload.startStr)
      setCalanderEndDate(payload.endStr)

      const formData = {
        start_date: payload.startStr,
        end_date: payload.endStr
      }

      setModalLoading(true);
      // setModalLoading(false);
      axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for':'get_coordination_staff_calander_list','formData': formData,'user': loggedUser})
        .then(res => {
          setModalLoading(false);
          const fetchedData = res.data;
          setEvents(fetchedData);
      })

    }

  }

  const renderEventContent  = (eventInfo) => {
    return (
      <>
      { eventInfo.event.extendedProps.type == 'count' &&
        <>
        <div>{eventInfo.event.title}</div>
        </>
      }
      { eventInfo.event.extendedProps.type == 'pc_rosters' &&
        <>
        <div>{eventInfo.event.title}</div>
        </>
      }
      { eventInfo.event.extendedProps.type == 'pns_rosters' &&
        <>
        <div>{eventInfo.event.title}</div>
        </>
      }

      { eventInfo.event.extendedProps.type == '' &&
        <>
          <div className={eventInfo.event.extendedProps.isToday == '1' ? 'isToday' : ''}>{eventInfo.event.title}</div>
        </>
      }
    </>
    )
  }
 
  const handleEventMount = info => {
  }

  const handleDateSelect = arg => {
  }

  const handleDateClick = (arg) => {

  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    // const event = arg.event;
    console.log( arg.event.extendedProps.event_id);

    if( arg.event.extendedProps.event_id ) {

      if( !isNaN(arg.event.extendedProps.event_id) ) {
        setRosteredDay('')
        setRosterDay('')
        setRosteredStaffs([])
        setEditID(arg.event.extendedProps.event_id);
        toggle()
        const formData = {
          event_id: arg.event.extendedProps.event_id,
        }
  
        setModalLoading(true);
        axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for':'get_coordination_staff_calander_details','formData': formData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            setRosteredStaffsArray(fetchedData.rostered_staffs_array)
            setRosteredDay(fetchedData.rostered_day)
            setRosteredStaffs(fetchedData.rostered_staffs)
            
            setRosterDay(fetchedData.roster_day)
            setIsEdit(true)
            
        })
      }

    }
    
  }

  const handleAllocateRosters = () => {
    
    setRosteredStaffsArray([])
    setRosteredDay('')
    setRosterDay('')
    toggle()
  }

  const handleRosteredDateChange = (date) => {
    setRosteredDay(date)
    setRosteredStaffsArray([])
    setRosteredStaffs([])
    const formData = {
      rostered_day: date
    }

    setModalLoading(true)
    axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for':'get_coordinates_staffs_dropdown','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;
        setRosteredStaffsArray(fetchedData);
        
    })
    
  }

  const toggleStaffSchedule = () => {
    if( staffScheduleModal ) {
      setStaffScheduleModal(false)
    } else {
      setStaffScheduleModal(true)
    }
  }

  const handleStaffSchedule = () => {
    toggleStaffSchedule()
  }


  const handleCloseModal = () => {
    toggleStaffSchedule()
  }


  return (
    <React.Fragment>
    { isLoading ? <TopProgressBar loading/> : '' }
    { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }

    <CoordinationStaffScheduleModal
      show={staffScheduleModal}
      onCloseModal={handleCloseModal}
    />

    {/* Coordination Team add schedule modal */}
    <Modal isOpen={modal} toggle={toggle}  size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit Details" : "Add Details"}

            
        </ModalHeader>
        <ModalBody>
            <Form
                onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
                }}
            >
            
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Rostered Day
                    </Label>
                    <Col sm={9}>
                        <InputGroup>
                            <Flatpickr
                                name="from_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    handleRosteredDateChange(dateStr);
                                }}
                                value={RosteredDay}
                                options={{
                                  altInput: true,
                                  altFormat: "d-m-Y",
                                  dateFormat: "Y-m-d",
                                  "locale": {
                                          "firstDayOfWeek": 1 // start week on Monday
                                      }
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Select Staff
                    </Label>
                    <Col sm={9}>
                        <Select
                        value={RosteredStaffs}
                        isMulti={true}
                        name="staffs"
                        options={RosteredStaffsArray}
                        onChange={(e) => setRosteredStaffs(e)}
                        className="select2-selection"
                        defaultValue={RosteredStaffs}
                        styles={{
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        />
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Roster Day
                    </Label>
                    <Col sm={9}>
                        <InputGroup>
                            <Flatpickr
                                name="from_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setRosterDay(dateStr);
                                }}
                            
                                value={RosterDay}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                <Col>
                    <div className="text-end">
                    <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={ModalLoading}
                    >
                        { !!isEdit ? 'Update' : 'Add' }
                    </button>
                    </div>
                </Col>
                </Row>

            </Form>
        </ModalBody>
    </Modal>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Coordination Team Schedule" />
          <Row>
            <Col>
              <button
                  type="btn"
                  className="btn btn-primary"
                  disabled={ModalLoading}
                  onClick={handleStaffSchedule}
              >
                Staff Schedule
              </button>
            </Col>
            <Col>
              <div className="text-end">
                    <button
                        type="btn"
                        className="btn btn-primary"
                        disabled={ModalLoading}
                        onClick={handleAllocateRosters}
                    >
                      Allocate Staff
                    </button>
              </div>
            </Col>
          </Row>
        <br/>
        <Row>
          <Col>
            <a className="badgecountTip"></a><span className="tipText"> - Unfilled rosters </span> <br/>
            <a className="pendingConfirmationTip"></a> <span className="tipText"> - Pending confirmation rosters</span><br/>
            <a className="pendingNursingTip"></a> <span className="tipText"> - Nursing rosters</span><br/>
          </Col>
        </Row>
          <Row>
            <Col lg="12">
            <br/>
              <Card>
                <CardBody>

                <FullCalendar
                          plugins={[
                            BootstrapTheme,
                            dayGridPlugin,
                            listPlugin,
                            interactionPlugin,
                          ]}
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          themeSystem="bootstrap"
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                          }}
                          eventOrder={id}
                          events={events}
                          editable={false}
                          droppable={false}
                          selectable={true}
                          // dateClick={handleDateClick}
                          navLinks={true}
                          eventClick={handleEventClick}
                          // eventDidMount={handleEventMount}
                          eventContent={renderEventContent}
                          // select={handleDateSelect}
                          datesSet={handleMonthChange}
                          displayEventEnd={true}
                          eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short',
                          }}
                          listDayFormat={true}
                          eventOrderStrict={false}
                         
                          
                        />
                
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default CoordinateTeamSchedule;