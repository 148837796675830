import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"
import {Name} from "../../../components/Common/TableColumns";
import { ToastContainer } from "react-toastify";
import axios from 'axios';
import { ACTION_URL, APP_URL } from "helpers/url_helper"

//Import

import Breadcrumbs from "components/Common/Breadcrumb"
import Spinners from "components/Common/Spinner";

import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
import AdhocServicesFormModal from "components/Forms/AdhocServicesFormModal"

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

function AdhocServicesList(){
  //meta title
  document.title="Adhoc Services List| Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const [modal, setModal] = useState(false)
  const [editID, setEditID] = useState('')
  const [ServiceType, setServiceType] = useState('service')
  const [roster, setRoster] = useState({})
  const [TableData, setTableData] = useState([])
  const [Loading, setLoading] = useState(false);
  const [axiosLoading, setAxiosLoading] = useState(false)


  const loadServices = () => {
    setTableData([])
    const formData = {
      service_type: 'service'
    }
    setAxiosLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'get_adhoc_service_lists','formData': formData,'user': loggedUser})
        .then(res => {
          setAxiosLoading(false);
          const fetchedData = res.data;
          if( fetchedData.status == 'success' ) {
            setTableData(fetchedData.serviceLists)
          }
    })
  }

  useEffect(() => {
    loadServices()
  },[]);


  const toggle = () => {
    if (modal) {
      setModal(false);
      setRoster({});
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setServiceType(user.type)
    setEditID(user.id)
    setRoster({
      edit_id: user.id,
      service_name: user.service_name,
      mobile_no: user.mobile_no
    })
    toggle();
  };

  const handleUserClicks = () => {
    setRoster({})
    setEditID('')
    toggle();
  }

  const handleCloseModal = (type, data) => {
    toggle()
    if( type == 'add' )  {
      setTableData(TableData => [data,...TableData]);
    }

    if( type == 'edit' ) {

      setTableData(TableData.map(rosters =>
        rosters.id.toString() === data.id.toString()
              ? { rosters, ...data }
              : rosters
      ))

    }

  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const columns = useMemo(
    () => [
      {
        Header: "Service Name",
        accessor: "service_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      { Loading ? <TopProgressBar loading/> : '' }
      { axiosLoading ? <Spinners setLoading={setLoading} /> : '' }

      <AdhocServicesFormModal
        editID={editID}
        rosterData={roster}
        serviceType={ServiceType}
        show={modal}
        onCloseModal={handleCloseModal}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Clients" breadcrumbItem="Adhoc Service Lists" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                    <TableContainer
                      columns={columns}
                      data={TableData}
                      isGlobalFilter={true}
                      showSearchBtn={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={50}
                      className="custom-header-css"
                      isAddBtn={true}
                      btnText={'Add Service List'}
                    />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default AdhocServicesList;