import React from "react";
import { Navigate } from "react-router-dom";

// Pages Component
import Chat from "../pages/Chat/Chat";

// File Manager
import FileManager from "../pages/FileManager/index";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// //Tasks
// import TasksList from "../pages/Tasks/tasks-list";
// import TasksCreate from "../pages/Tasks/tasks-create";
// import TasksKanban from "../pages/Tasks/tasks-kanban";

// //Projects
// import ProjectsGrid from "../pages/Projects/projects-grid";
// import ProjectsList from "../pages/Projects/projects-list";
// import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview";
// import ProjectsCreate from "../pages/Projects/projects-create";

// //Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
// import EmailInbox from "../pages/Email/email-inbox";
// import EmailRead from "../pages/Email/email-read";
// import EmailBasicTemplte from "../pages/Email/email-basic-templte";
// import EmailAlertTemplte from "../pages/Email/email-template-alert";
// import EmailTemplateBilling from "../pages/Email/email-template-billing";

//Invoices
// import InvoicesList from "../pages/Invoices/invoices-list";
// import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";


// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";

// import Alerts from "../pages/Alerts/";

import AlertRosterPage from "../pages/Alerts/";
import EmergencyActiveAlerts from "../pages/EmergencyAlerts/ActiveAlerts";
import EmergencyArchivedAlerts from "../pages/EmergencyAlerts/ArchivedAlerts";
import BroadcastRosters from "../pages/BroadcastRosters/";


//Tacveiha Pages
import AllRosters from "../pages/Rosters/AllRosters";
import TodaysRosters from "../pages/Rosters/TodaysRosters";
import GroupRosters from "../pages/Rosters/GroupRosters";
import Providers from "../pages/Departments/Providers";
import ThirdPartyContractors from "../pages/Departments/ThirdPartyContractors";
// import AddRoster from "../pages/Rosters/AddRosters";

//case managers
import Casemanagers from "../pages/Casemanagers/Casemanagers";
import AddCasemanager from "../pages/Casemanagers/Add";

//clients
import Clients from "../pages/Clients/Clients";
import ArchivedClients from "../pages/Clients/ArchivedClients";
import ClientServiceTypes from "../pages/Clients/AddServiceTypes";
import ClientServiceList from "../pages/Clients/ClientServiceList";
import ClientIsolationList from "../pages/Clients/IsolationList";


//contractors
import Contractors from "../pages/Contractors/Contractors";
import ContractorsDocs from "../pages/Contractors/Documents";


//staff leaves
import StaffLeavesProcessed from "../pages/StaffLeaves/Processed";
import StaffLeavesApproved from "../pages/StaffLeaves/Approved";
import StaffLeavesApprovedArchived from "../pages/StaffLeaves/ApprovedArchived";
import StaffLeavesUnApproved from "../pages/StaffLeaves/UnApproved";
import StaffLeavesDeleted from "../pages/StaffLeaves/Deleted";

//client away
import ClientAwayAlertsApproved from "../pages/ClientAwayAlerts/Approved";
import ClientAwayAlertsUnApproved from "../pages/ClientAwayAlerts/UnApproved";
import ClientAwayAlertsDeleted from "../pages/ClientAwayAlerts/Deleted";


//Roster Reports
import RosterReport from "../pages/Reports/RosterReport";
import ReportWithNotes from "../pages/Reports/ReportWithNotes";
import ChecklistReport from "../pages/Reports/ChecklistReport";
import TwoHourRosterReport from "../pages/Reports/TwoHourRosterREport";
import GroupRosterReport from "../pages/Reports/GroupRosterReport";
import BuddyReport from "../pages/Reports/BuddyReports";
import SupportWorkerDocHrReport from "../pages/Reports/SupportWorkerDocHrReport";

//Incident Report
import IncidentReportActive from "../pages/IncidentReports/Active";
import IncidentReportCompleted from "../pages/IncidentReports/Completed";
import IncidentReportAdd from "../pages/IncidentReports/Add";

//News Feeds 
import NewsFeeds from "../pages/NewsFeed/Active";
import NewsFeedsArchived from "../pages/NewsFeed/Archived";
import NewsFeedsViewed from "../pages/NewsFeed/Viewed";


//Client Types 
import ClientTypes from "../pages/ClientTypes/ClientTypesActive";
import ClientPackageTypes from "../pages/ClientTypes/PackageTypes";
import ClientPackageTypesArchived from "../pages/ClientTypes/Archived";

import Settings from "pages/Settings";
import PackageReport from "pages/PackageReport";
import PayCatetory from "pages/PayCatetory";
import VaccinationDetails from "pages/VaccinationDetails";
import CovidQuestions from "pages/CovidQuestions";
import HrDocumentsName from "pages/HrDocumentsName";
import SendSms from "pages/SendSms";
import AddRosters from "pages/Rosters/AddRosters";
import ClientsProfile from "../pages/Clients/ClientsProfile";
import SupportWorkers from "pages/Staffs/SupportWorker";
import CsmStaffs from "pages/Staffs/CSMAdminLogins";
import StaffsServiceTypes from "pages/Staffs/ServiceTypes";
import StaffsLanguages from "pages/Staffs/Languages";
import AddStaffs from "pages/Staffs/AddStaffs";
import EditStaff from "pages/Staffs/EditStaff";
import SupportStaffs from "pages/Staffs/SupportStaffs";
import AdminStaffs from "pages/Staffs/AdminStaffs";
import HrManagers from "pages/Staffs/HumanResourceManager";
import ArchivedSupportWorkers from "pages/Staffs/ArchivedSupportWorker";
import EditSupportStaff from "pages/Staffs/EditSupportStaff";
import AddClient from "pages/Clients/Add";
import PublicHolidays from "pages/PublicHoliday";
import StaffLeavesAdd from "pages/StaffLeaves/Add";
import ClientsAwayAdd from "pages/ClientAwayAlerts/Add";
import Feedbacks from "pages/Feedback";
import Questions from "pages/Recruitment/Questions";
import CandidateList from "pages/Recruitment/Candidates";
import EmployedLists from "pages/Recruitment/Employeds";
import ClientsPlan from "pages/Clients/ClientPlans";
import SupportWorkerAllDocHrReport from "pages/Reports/SupportWorkerAllDocHrReport";
import RosterDepartmentReport from "pages/Reports/RosterDepartmentReport";
import DepartmentProfile from "pages/Profile/Department";
import CaseManagerProfile from "pages/Profile/CaseManager";
import HRProfile from "pages/Profile/HRManager";
import AdminAlertRostersPage from "pages/CareNotesReview";
import CompletedRostersPage from "pages/CompletedRosters";
import NoClockInAllRostersActive from "pages/NoClockinRosters/Active";
import NoClockInAllRostersArchived from "pages/NoClockinRosters/Archived";
import ChecklistAlertsActive from "pages/ChecklistAlerts/Active";
import ChecklistAlertsArchived from "pages/ChecklistAlerts/Archived";
import AdhocServicesList from "pages/AdhocServices/Services";
import AdhocSupppliersList from "pages/AdhocServices/Suppliers";
import AdhocEmailList from "pages/AdhocServices/Emails";
import CoordinateTeamSchedule from "pages/StaffsSchedule";
import RestrictedPage from "pages/RestrictedPage";




const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  
  { path: "/public-holiday", component: <PublicHolidays /> },
  { path: "/send-sms", component: <SendSms /> },

  // { path: "/alerts", component: <Alerts /> },
  // { path: "/alerts", component: <Alerts /> },
  // { path: "/alerts", component: <Alerts /> },

  { path: "/alerts/:type", component: <AlertRosterPage />},
  { path: "/alerts/:type", component: <AlertRosterPage />},
  { path: "/alerts/:type", component: <AlertRosterPage />},

  { path: "/emergency-active-alerts", component: <EmergencyActiveAlerts /> },
  { path: "/emergency-archived-alerts", component: <EmergencyArchivedAlerts /> },

  { path: "/broadcast-rosters", component: <BroadcastRosters /> },

  //Tacveiha Routs
  { path: "/rosters/add-rosters", component: <AddRosters />},
  { path: "/rosters/all-rosters", component: <AllRosters />},
  { path: "/rosters/todays-rosters", component: <TodaysRosters />},
  { path: "/rosters/group-rosters", component: <GroupRosters />},
  // { path: "/add-roster", component: <AddRoster />},

  //departments
  { path: "/providers", component: <Providers />},
  { path: "/third-party-contractors", component: <ThirdPartyContractors />},

   //casemanagers
   { path: "/casemanagers", component: <Casemanagers />},
   { path: "/casemanager/add", component: <AddCasemanager />},

  //clients
  { path: "/clients", component: <Clients />},
  { path: "/clients/archived", component: <ArchivedClients />},
  { path: "/clients/add-client", component: <AddClient />},
  { path: "/clients/edit-clients/:id", component: <ClientsProfile />},
  { path: "/client-service-types", component: <ClientServiceTypes />},
  { path: "/client-service-lists", component: <ClientServiceList />},
  { path: "/client-isolation-lists", component: <ClientIsolationList />},
  // { path: "/client-isolation-lists", component: <ClientIsolationList />},



  //staffs
  { path: "/staffs/services-types", component: <StaffsServiceTypes />},
  { path: "/staffs/languages", component: <StaffsLanguages />},
  // { path: "/staffs/:type", component: <SupportWorkers />},
  { path: "/staffs/csm-staffs", component: <CsmStaffs />},
  { path: "/staffs/add-staff", component: <AddStaffs />},
  { path: "/staffs/edit-staff/:id", component: <EditStaff />},
  { path: "/staffs/edit-support-staff/:id", component: <EditSupportStaff />},
  { path: "/staffs/support-staffs", component: <SupportStaffs />},
  { path: "/staffs/admin-staffs", component: <AdminStaffs />},
  { path: "/staffs/hr-managers", component: <HrManagers />},
  { path: "/staffs", component: <SupportWorkers />},
  { path: "/archived-staffs", component: <ArchivedSupportWorkers />},
  // { path: "/client-service-lists", component: <ClientServiceList />},
  // { path: "/client-isolation-lists", component: <ClientIsolationList />},
  // { path: "/client-isolation-lists", component: <ClientIsolationList />},

  //contractors
  { path: "/contractors", component: <Contractors />},
  // { path: "/contractors/add", component: <ContractorsAdd />},
  { path: "/contractors/documents", component: <ContractorsDocs />},

   //Staff Leaves
   { path: "/staffleaves/add", component: <StaffLeavesAdd />},
   { path: "/staffleaves/processed", component: <StaffLeavesProcessed />},
   { path: "/staffleaves/approved", component: <StaffLeavesApproved />},
   { path: "/staffleaves/approved-archived", component: <StaffLeavesApprovedArchived />},
   { path: "/staffleaves/unapproved", component: <StaffLeavesUnApproved />},
   { path: "/staffleaves/deleted", component: <StaffLeavesDeleted />},

   //client away
   { path: "/clientsawayalerts/add", component: <ClientsAwayAdd />},
   { path: "/clientsawayalerts/approved", component: <ClientAwayAlertsApproved />},
   { path: "/clientsawayalerts/unapproved", component: <ClientAwayAlertsUnApproved />},
   { path: "/clientsawayalerts/deleted", component: <ClientAwayAlertsDeleted />},

   //Reports
   { path: "/reports/roster-report", component: <RosterReport />},
   { path: "/reports/report-with-notes", component: <ReportWithNotes />},
   { path: "/reports/checklist-report", component: <ChecklistReport />},
   { path: "/reports/two-hour-roster-report", component: <TwoHourRosterReport />},
   { path: "/reports/group-roster-report", component: <GroupRosterReport />},
   { path: "/reports/buddy-report", component: <BuddyReport />},
   { path: "/reports/support-worker-doc-hr-report", component: <SupportWorkerDocHrReport />},
   { path: "/reports/support-worker-all-doc-hr-report", component: <SupportWorkerAllDocHrReport />},
   { path: "/reports/roster-department-report", component: <RosterDepartmentReport />},

   //Incident Reports
   { path: "/incident-reports/active", component: <IncidentReportActive />},
   { path: "/incident-reports/completed", component: <IncidentReportCompleted />},
   { path: "/incident-reports/add", component: <IncidentReportAdd />},


   //News Feeds 
   { path: "/news-feeds/active", component: <NewsFeeds />},
   { path: "/news-feeds/archived", component: <NewsFeedsArchived />},
   { path: "/news-feeds-viewed/:id", component: <NewsFeedsViewed />},

  //Client Types 
  { path: "/client-types", component: <ClientTypes />},
  { path: "/client-package-types", component: <ClientPackageTypes />},
  { path: "/client-package-types/archived", component: <ClientPackageTypesArchived />},

  //Site Settings Types 
  { path: "/settings", component: <Settings />},


  //package report settings Settings Types 
  { path: "/package-report-settings", component: <PackageReport />},

  //pay category list
  { path: "/pay-category-list", component: <PayCatetory />},


  //Vacination Details
  { path: "/vaccination-details", component: <VaccinationDetails />},

  //Checklist Questions
  { path: "/checklist-questions", component: <CovidQuestions />},

  //Feedbacks
  { path: "/feedbacks", component: <Feedbacks />},

  //profiles
  { path: "/department-profile", component: <DepartmentProfile />},
  { path: "/case-manager-profile", component: <CaseManagerProfile />},
  { path: "/hr-manager-profile", component: <HRProfile />},

  //care notes review
  { path: "/admin-staff-rosters", component: <AdminAlertRostersPage />},
  { path: "/completed-rosters", component: <CompletedRostersPage />},
  
  
  //No clock in rosters
  { path: "/no-clockin-rosters-active", component: <NoClockInAllRostersActive />},
  { path: "/no-clockin-rosters-archived", component: <NoClockInAllRostersArchived />},

    //checklist alerts 
    { path: "/checklist-alerts-active", component: <ChecklistAlertsActive />},
    { path: "/checklist-alerts-archived", component: <ChecklistAlertsArchived />},

  // //chat
  // { path: "/chat", component: <Chat /> },

  // //File Manager
  // { path: "/apps-filemanager", component: <FileManager /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // Hr Documents
  { path: "/hr-documents-name-list", component: <HrDocumentsName /> },

  //Recruitments
  { path: "/recruitments/interview-questions", component: <Questions /> },
  { path: "/recruitments/candidate-lists", component: <CandidateList /> },
  { path: "/recruitments/employed-lists", component: <EmployedLists /> },

  { path: "/clients/clientsplan", component: <ClientsPlan /> },

  { path: "/adhoc-services-list", component: <AdhocServicesList /> },
  { path: "/adhoc-suppliers-list", component: <AdhocSupppliersList /> },
  { path: "/adhoc-emails-list", component: <AdhocEmailList /> },

  { path: "/coordinate-team-schedule", component: <CoordinateTeamSchedule /> },


  { path: "/restricted", component: <RestrictedPage /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },

];

export { authProtectedRoutes, publicRoutes };
