import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { COORDINATETEAM_PAGE, APP_URL} from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { Name } from "components/Common/TableColumns";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";
import TableContainer from "components/Common/TableContainer";


  const CoordinationStaffScheduleModal = ({show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);

    const[RosteredStaffs, setRosteredStaffs] = useState([]);
    const[RosteredDate, setRosteredDate] = useState('');


    useEffect(() => {
        if( show ) { 
            setRosteredStaffs([]);
            setRosteredDate(new Date());
            const formData = {
                rostered_date: new Date()
            }
            setModalLoading(true);
            axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for':'get_today_rostered_staffs','formData':formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                setRosteredStaffs(res.data.rosteredStaffs);
                // setRosteredDate(res.data.rostered_date);
            })
        }
    }, [show])
    

    const handleRosterDateChange = (dateStr) => {
        setRosteredDate(dateStr)
        setRosteredStaffs([]);
            const formData = {
                rostered_date: dateStr
            }
            setModalLoading(true);
            axios.post(APP_URL+COORDINATETEAM_PAGE,{'data_for':'get_today_rostered_staffs','formData':formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                setRosteredStaffs(res.data.rosteredStaffs);
            })

    }

    const columns = useMemo(
        () => [
          {
            Header: "Staff Name",
            accessor: "staff_name",
            filterable: true,
            Cell: cellProps => {
              return (
                <Name {...cellProps} />
              )
            },
          },
          {
            Header: "Start Time",
            accessor: "start_time",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Finish Time",
            accessor: "finish_time",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Roster Day",
            accessor: "roster_day",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
        ]
    )


    return (
        <>

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable>
        <ModalHeader toggle={onCloseModal} tag="h4">
            Staffs Schedule { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
        </ModalHeader>
        <ModalBody>

            <Row className="mb-4">
                <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                >
                    Rostered Day
                </Label>
                <Col sm={9}>
                    <InputGroup>
                        <Flatpickr
                            name="from_date"
                            type="text"
                            className="form-control d-block"
                            placeholder="dd-mm-YYYY"
                            onChange={(selectedDates, dateStr, instance) => {
                                handleRosterDateChange(dateStr);
                            }}
                        
                            value={RosteredDate}
                            options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                defaultDate: new Date(),
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                            }}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                <TableContainer
                    columns={columns}
                    data={RosteredStaffs}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    customPageSize={50}
                    className="custom-header-css"
                  />
                </Col>
            </Row>
            <br/>
            <br/>
            <br/>
        </ModalBody>
        </Modal>

        </>
    )

}

CoordinationStaffScheduleModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default CoordinationStaffScheduleModal;