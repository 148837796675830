import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ACTION_URL, APP_URL, SITE_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";


  const PackageLevelModal = ({clientID, show, onCloseModal, showPackageBtn}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [PackageTypeDropdown, setPackageTypeDropdown] = useState([]);
    const [PackageType, setPackageType] = useState([]);
    const [ClientSupportType, setClientSupportType ] = useState([]);
    const [ClientPackageStartDate, setClientPackageStartDate] = useState('');
    const [ClientPackageEndDate, setClientPackageEndDate] = useState('');
    const [PackageLevelDropdown, setPackageLevelDropdown] = useState([]);
    const [PackageLevel, setPackageLevel] = useState([]);
    const [PackageAmount, setPackageAmount] = useState('0.00');
    const [PackageHourlyRate, setPackageHourlyRate] = useState('0.00');

    const [DailySubsidy, setDailySubsidy] = useState('0.00');
    const [WeeklySubsidy, setWeeklySubsidy] = useState('0.00');
    const [MonthlySubsidy, setMonthlySubsidy] = useState('0.00');
    const [YearlySubsidy, setYearlySubsidy] = useState('0.00');
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if( show ) { 
            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'load_package_levels','uid' : clientID})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;

                if( fetchedData.status == 'success' ) {
                    
                    setDisabled(true);
                    setPackageTypeDropdown(fetchedData.ClientTypes);
                    setPackageLevelDropdown(fetchedData.PackageLevels);
                    
                }

                if( fetchedData.status == 'error' ) {
                    setDisabled(false);
                    toast.error(fetchedData.html, { autoClose: 5000 })
                }
                
            })
        }
    }, [show]);


    const handlePackageTypes = (e) => {
        setPackageType(e);
    
        const formData = {
            client_type: e.value
        }
        setModalLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_package_types','formData':formData})
        .then(res => {
            setModalLoading(false);
          const fetchedData = res;
            // setDepartment_dropdown(res.data);
        })
    
    }
    
    const handlePackageLevel = (e) => {
        setPackageLevel(e.value);    
        const formData = {
            client_type: PackageType.value,
            client_package_level: e.value
        }
        setModalLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_package_leves_rate','formData':formData})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            setDailySubsidy(fetchedData.daily_subsidy);
            setWeeklySubsidy(fetchedData.weekly_subsidy);
            setMonthlySubsidy(fetchedData.monthly_subsidy);
            setYearlySubsidy(fetchedData.yearly_subsidy);
    
        })
    
    }

    const handleAddPackage = () => {

        if( PackageType == '' ) {
            toast.error("Please select package type", { autoClose: 5000 });
            return false;
        }

        if( PackageType.value == 1 ) {

            if( ClientSupportType == '' ) {
                toast.error("Please add support types", { autoClose: 5000 });
                return false;
            }

            if( PackageAmount == '' ) {
                toast.error("Please select package amount", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageStartDate == '' ) {
                toast.error("Please select start date", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageEndDate == '' ) {
                toast.error("Please select end date", { autoClose: 5000 });
                return false;
            }

        }

        if( PackageType.value == 2 ) {

            if( PackageLevel == '' ) {
                toast.error("Please select package level", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageStartDate == '' ) {
                toast.error("Please select start date", { autoClose: 5000 });
                return false;
            }

            if( WeeklySubsidy == '' && MonthlySubsidy == '' && YearlySubsidy == '') {
                toast.error("Please provide subsidy amount", { autoClose: 5000 });
                return false;
            }

        }

        if( PackageType.value == 3 ) {

            if( ClientSupportType == '' ) {
                toast.error("Please add support types", { autoClose: 5000 });
                return false;
            }

            if( PackageHourlyRate == '' ) {
                toast.error("Please provide hourly rate", { autoClose: 5000 });
                return false;
            }

            if( PackageAmount == '' ) {
                toast.error("Please add total amount", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageStartDate == '' ) {
                toast.error("Please select start date", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageEndDate == '' ) {
                toast.error("Please select end date", { autoClose: 5000 });
                return false;
            }

        }

        const formData = {
            client_id: clientID,
            ctid: PackageType,
            cplid: PackageLevel,
            support_type: ClientSupportType,
            amount: PackageAmount,
            hourly_rate: PackageHourlyRate,
            daily_subsidy: DailySubsidy,
            weekly_subsidy: WeeklySubsidy,
            monthly_subsidy: MonthlySubsidy,
            yearly_subsidy: YearlySubsidy,

            start_date : ClientPackageStartDate,
            end_date : ClientPackageEndDate,
        }

        // console.log(formData);
        // add data
        setModalLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'add_client_package_levels','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                onCloseModal();
                showPackageBtn();
                setPackageType([]);
                setPackageLevel([]);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });

            }

        })

    }
    
    return (

        <>

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={onCloseModal} tag="h4">
                Add Package { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>
            <ModalBody style={{ minHeight: '400px' }}>
        
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                    Package Type
                    </Label>
                    <Col sm={9}>
                    
                        <Select
                            value={PackageType}
                            name="ctid"
                            options={PackageTypeDropdown}
                            onChange={(e) => handlePackageTypes(e)}
                            className="select2-selection"
                            defaultValue={PackageType}
                            styles={{
                            menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                    </Col>
                
                </Row>

                { PackageType.value == '1' && (

                    <>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Support Type
                        </Label>
                        <Col sm={9}>

                        <CreatableSelect 
                        isMulti
                        name="support_type"
                        value={ClientSupportType}
                        onChange={(e) => {
                            setClientSupportType('');
                            setClientSupportType(e);
                        }}
                            />

                            {/* <Input
                                name="support_type"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.support_type} /> */}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Amount
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="amount"
                                type="text"
                                onChange={(e) => setPackageAmount(e.target.value)}
                                value={PackageAmount} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Start Date
                        </Label>
                        <Col sm={9}>
                        <InputGroup>
                            <Flatpickr
                                name="start_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setClientPackageStartDate(dateStr);
                                }}
                                // onBlur={validation.handleBlur}
                                value={ClientPackageStartDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                // maxDate: 'today',
                                //   defaultDate: 'today',
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                        </Col>
                    </Row>


                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            End Date
                        </Label>
                        <Col sm={9}>
                        <InputGroup>
                            <Flatpickr
                                name="end_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setClientPackageEndDate(dateStr);
                                }}
                                // onBlur={validation.handleBlur}
                                value={ClientPackageEndDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                minDate: ClientPackageStartDate,
                                //   defaultDate: 'today',
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                        </Col>
                    </Row>
                    
                    </>
                )}
                    
                { PackageType.value == 2 && (

                    <>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Package Level
                        </Label>
                        <Col sm={9}>

                            <Select
                            value={PackageLevel.value}
                            name="cplid"
                            options={PackageLevelDropdown}
                            onChange={(e) => handlePackageLevel(e)}
                            className="select2-selection"
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                            defaultValue={PackageLevelDropdown}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Start Date
                        </Label>
                        <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="start_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageStartDate(dateStr);
                                    }}
                                    // onBlur={validation.handleBlur}
                                    value={ClientPackageStartDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    // maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Daily Subsidy
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="daily_subsidy"
                                type="text"
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                value={DailySubsidy}
                                readOnly={true} />
                                
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Weekly Subsidy
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="weekly_subsidy"
                                type="text"
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                value={WeeklySubsidy} />
                                
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Monthly Subsidy
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="monthly_subsidy"
                                type="text"
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                value={MonthlySubsidy} />
                                
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Yearly Subsidy
                        </Label>
                        <Col sm={9}>
                            <Input
                                name="yearly_subsidy"
                                type="text"
                                // onChange={validation.handleChange}
                                // onBlur={validation.handleBlur}
                                value={YearlySubsidy} />
                                
                        </Col>
                    </Row>

                    </>
                )}
                

                { PackageType.value == '3' && (

                <>
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Support Type
                    </Label>
                    <Col sm={9}>

                    <CreatableSelect 
                    isMulti
                    name="support_type"
                    value={ClientSupportType}
                    placeholder="Add Support Types"
                    onChange={(e) => {
                        setClientSupportType('');
                        setClientSupportType(e);
                    }}
                    />
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Hourly Rate
                    </Label>
                    <Col sm={9}>
                        <Input
                            name="hourly_rate"
                            type="text"
                            onChange={(e) => setPackageHourlyRate(e.target.value)}
                            // onBlur={validation.handleBlur}
                            value={PackageHourlyRate} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Total Amount
                    </Label>
                    <Col sm={9}>
                        <Input
                            name="amount"
                            type="text"
                            onChange={(e) => setPackageAmount(e.target.value)}
                            // onBlur={validation.handleBlur}
                            value={PackageAmount} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Start Date
                    </Label>
                    <Col sm={9}>
                    <InputGroup>
                        <Flatpickr
                            name="start_date"
                            type="text"
                            className="form-control d-block"
                            placeholder="dd-mm-YYYY"
                            onChange={(selectedDates, dateStr, instance) => {
                                setClientPackageStartDate(dateStr);
                            }}
                            // onBlur={validation.handleBlur}
                            value={ClientPackageStartDate}
                            options={{
                            altInput: true,
                            altFormat: "d-m-Y",
                            dateFormat: "Y-m-d",
                            // maxDate: 'today',
                            //   defaultDate: 'today',
                            "locale": {
                                    "firstDayOfWeek": 1 // start week on Monday
                                }
                            }}
                        />
                        </InputGroup>
                    </Col>
                </Row>


                <Row className="mb-4">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        End Date
                    </Label>
                    <Col sm={9}>
                    <InputGroup>
                        <Flatpickr
                            name="end_date"
                            type="text"
                            className="form-control d-block"
                            placeholder="dd-mm-YYYY"
                            onChange={(selectedDates, dateStr, instance) => {
                                setClientPackageEndDate(dateStr);
                            }}
                            // onBlur={validation.handleBlur}
                            value={ClientPackageEndDate}
                            options={{
                            altInput: true,
                            altFormat: "d-m-Y",
                            dateFormat: "Y-m-d",
                            minDate: ClientPackageStartDate,
                            //   defaultDate: 'today',
                            "locale": {
                                    "firstDayOfWeek": 1 // start week on Monday
                                }
                            }}
                        />
                        </InputGroup>
                    </Col>
                </Row>

                </>
                )}
                { disabled &&
                <Row className="mb-4">
                    <Col>
                    <div className="text-end">
                        <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={ModalLoading}
                        onClick={handleAddPackage}
                        >
                        Add
                        </button>
                    </div>
                    </Col>
                </Row>

                }

            </ModalBody>

        </Modal>
    </>
    )

}

PackageLevelModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any,
    showPackageBtn: PropTypes.func
}

export default PackageLevelModal;