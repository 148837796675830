import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from "react-toastify";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import * as moment from "moment";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Spinners from "components/Common/Spinner";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle,
    ModalFooter
} from "reactstrap";


const ClientDischargePlan = ({clientID, clientFname, clientLname, show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [modalLoading, setModalLoading] = useState(false);
    const [clientInfo, setClientInfo] = useState([]);
    const [type, setType] = useState('add');
    const [loading, setLoading] = useState(false);
    const [planID, setPlanID] = useState('');
    const [activityLog, setActivityLog] = useState([]);
    const [dischargeFormData, setDischargeFormData] = useState([]);
    const [clientServiceList, setClientServiceList] = useState([]);

    const [dischargeNoticeReceivedDate, setDischargeNoticeReceivedDate] = useState('')
    const [servicesDropdown, setServicesDropdown] = useState([]);
    const [clientService, setClientService] = useState([]);
    const [serviceProvider, setServiceProvider] = useState('');
    const [notificationDate, setNotificationDate] = useState();
    const [serviceID, setServiceID] = useState('');



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            client_name: ( clientInfo && clientInfo.client_name) || "",
            client_dob: ( clientInfo && clientInfo.client_dob) || "",
            client_address: ( clientInfo && clientInfo.client_address) || "",
            client_type: ( clientInfo && clientInfo.client_type) || "",
            discharge_notice_received_date: ( clientInfo && clientInfo.discharge_notice_received_date) || "",
            discharge_notice_received_from: ( clientInfo && clientInfo.discharge_notice_received_from) || "",  
        },
        validationSchema: Yup.object({
            // vaccination_flag: Yup.string().required("Required"),
        }),
        onSubmit: values => {

            if( dischargeNoticeReceivedDate == '' ) {
                toast.error('please provide discharge notice received date', { autoClose: 5000 });
                return false;
            }

            if( values.discharge_notice_received_from == '' ) {
                toast.error('please provide discharge notice received from', { autoClose: 5000 });
                return false;
            }

            if( clientService.value == '' ) {
                toast.error('please provide current service', { autoClose: 5000 });
                return false;
            }

            if( serviceProvider == '' ) {
                toast.error('please provide provider details', { autoClose: 5000 });
                return false;
            }

            if( notificationDate == '' ) {
                toast.error('please provide notificaiton date', { autoClose: 5000 });
                return false;
            }
    
            const formData = {
                type: type,
                user_id: clientID,
                edit_id: clientInfo.df_id,
                service_id: serviceID,
                client_name: values.client_name ,
                client_dob: values.client_dob,
                client_address: values.client_address,
                client_type: values.client_type,
                discharge_notice_received_date: dischargeNoticeReceivedDate,
                discharge_notice_received_from: values.discharge_notice_received_from,
                services: clientService,
                service_provider: serviceProvider,
                notification_date: notificationDate,
            }

            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'add_client_discharge_form','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {
                    toast.success(fetchedData.html, { autoClose: 5000 });
                }
    
                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.html, { autoClose: 5000 });
                }
    
            })
    
        }
    })

    useEffect(() => {

        if( show ) {

            const formData = {
                uid: clientID
            }
            setModalLoading(true)
            axios.post(APP_URL+ACTION_URL,{'data_for':'client_discharge_form_data','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {
                    setType('edit');
                    setClientInfo(fetchedData.data);
                    setDischargeFormData(fetchedData.dischargeFormData);
                    setServicesDropdown(fetchedData.services_dropdown);
                    setClientService({value:fetchedData.services.id,label: fetchedData.services.service_name});
                    setNotificationDate(fetchedData.service_list.notification_date);
                    setServiceProvider(fetchedData.service_list.provider);
                    setServiceID(fetchedData.service_list.id)
                    setDischargeNoticeReceivedDate(fetchedData.data.discharge_notice_received_date);

                }

                if( fetchedData.status == 'error' ) {
                    setType('add');
                    setClientInfo(fetchedData.data);
                    setDischargeFormData([]);
                    setServicesDropdown(fetchedData.services_dropdown);
                    setClientService([]);
                    setNotificationDate('');
                    setServiceProvider('');
                    setServiceID('')
                    setDischargeNoticeReceivedDate('');
                }

            })

        }
    },[show])

    const handleAids = (event) => {
        const checkedId = event.target.value;
        console.log(checkedId);
        if( event.target.checked ) {
            setAids([...Aids, checkedId]);
        } else{
            setAids( Aids.filter(id =>id != checkedId));
        }
    }

    const handleSubmitBtn = () => {
        validation.handleSubmit();
    }

    const handleAddRow = () => {

    }

    return (
        <>

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable  s>
            <ModalHeader toggle={onCloseModal} tag="h4">
                Client Discharge From{ modalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col md={12}>
                    <Form>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Client Name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={validation.values.client_name}
                                        readonly
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Client DOB</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={validation.values.client_dob}
                                        readonly
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Client Address</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={validation.values.client_address}
                                        readonly
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Client Type</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={validation.values.client_type}
                                        readonly
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Discharge Notice Received Date</Label>
                                    <InputGroup>
                                    <Flatpickr
                                        name="create_date"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setDischargeNoticeReceivedDate(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.discharge_notice_received_date || dischargeNoticeReceivedDate}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label>Discharge Notice Received From</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={validation.values.discharge_notice_received_from}
                                        onChange={validation.handleChange}
                                        name="discharge_notice_received_from"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            
                            <table>
                                <thead>
                                    <tr>
                                    <th>Current Services</th>
                                    <th>Provider</th>
                                    <th>Notification Date</th>
                                    {/* <th><Button type="btn" className="btn btn-info" onClick={handleAddRow}>Add</Button></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Select
                                                name="services"
                                                value={clientService}
                                                options={servicesDropdown}
                                                onChange={(e) => setClientService(e)}
                                                className="select2-selection"
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                }}
                                            />
                                        </td>
                                        <td><Input
                                            name="service_provider"
                                            type="text"
                                            onChange={(e) => setServiceProvider(e.target.value)}
                                            value={serviceProvider}
                                        />
                                        </td>
                                        <td>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="create_date"
                                                    type="text"
                                                    className="form-control d-block"
                                                    placeholder="dd-mm-YYYY"
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        setNotificationDate(dateStr);
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={notificationDate}
                                                    options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    maxDate: 'today',
                                                    "locale": {
                                                            "firstDayOfWeek": 1 // start week on Monday
                                                        }
                                                    }}
                                                />
                                            </InputGroup>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </Row>
                        

                        </Form>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <button type="btn" disabled={modalLoading} onClick={handleSubmitBtn} className="btn btn-primary w-md">
                    { type == 'edit' ? 'Update' : 'Add' }
                    </button>
                </div>
            </ModalFooter>
        
    </Modal>

        </> 
    )

}

ClientDischargePlan.propTypes = {
        onCloseModal: PropTypes.func,
        show: PropTypes.any,
        handleSubmitBtn: PropTypes.func
    }

export default ClientDischargePlan;