import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"

function RestrictedPage () {
    //meta title
    document.title="Access denied  | Tacveiha Panel";
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    const [ModalLoading, setModalLoading] = useState(false);
  
    const [isLoading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);


    return (
        <React.Fragment>
        { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
        <div className="page-content">
            <Container fluid>
            <Breadcrumbs title="#" breadcrumbItem="Restricted Page" />
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row className="mb-2">
                                    You do not have access to this features, Please contact admin.
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        </React.Fragment>
    );


}

export default RestrictedPage;