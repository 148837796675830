import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from "react-toastify";
import { useFormik} from "formik";
import { ACTION_URL, APP_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle,
    ModalFooter
} from "reactstrap";
import TopProgressBar from "components/Common/TopProgressBar";


const AdhocServicesFormModal = ({editID, serviceType, rosterData, show, onCloseModal}) =>  {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ModalLoading, setModalLoading] = useState(false);


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            service_name: ( rosterData && rosterData.service_name ) || "",
            mobile_no: ( rosterData && rosterData.mobile_no) || ""
        },
        validationSchema: Yup.object({
            service_name: Yup.string().required("This field is required"),
        }),
        onSubmit: values => {

            if( serviceType == 'supplier' ) {
                if( values.mobile_no == '' ) {
                    toast.error('Please provide supplier mobile number', { autoClose: 5000 })
                    return;
                }
            }

            if( editID != '' ) {

                const formData = {
                    edit_id: editID,
                    service_type: serviceType,
                    service_name : values.service_name,
                    mobile_no: values.mobile_no,
                };
        
                console.log( formData);
                // add data
                setModalLoading(true);
                axios.post(APP_URL+ACTION_URL,{'data_for':'edit_adhoc_services','formData': formData,'user': loggedUser})
                    .then(res => {
                    setModalLoading(false);
                    
                    const fetchedData = res.data;
                    if( fetchedData.status == 'success' ) {
                        toast.success(fetchedData.html, { autoClose: 5000 })
                        onCloseModal('edit',fetchedData.serviceData)
                    }
            
                    if( fetchedData.status == 'error' ) {
                        toast.error(fetchedData.html, { autoClose: 5000 });
                    }
        
                });

            } else {

                const formData = {
                    service_type: serviceType,
                    service_name : values.service_name,
                    mobile_no: values.mobile_no,
                };
        
                console.log( formData);

                // add data
                setModalLoading(true);
                axios.post(APP_URL+ACTION_URL,{'data_for':'add_adhoc_services','formData': formData,'user': loggedUser})
                    .then(res => {
                    setModalLoading(false);
                    
                    const fetchedData = res.data;
                    if( fetchedData.status == 'success' ) {
                        toast.success(fetchedData.html, { autoClose: 5000 });   
                        validation.resetForm();
                        setIsEdit(false);
                        onCloseModal('add',fetchedData.serviceData)
                    }
            
                    if( fetchedData.status == 'error' ) {
                        toast.error(fetchedData.html, { autoClose: 5000 });
                    }
        
                });

            }
        },
    })

    // useEffect(() => {

    //     if( show ) {

    //         const formData = {
    //             edit_id: editID,
    //         }
    //         setModalLoading(true)
    //         setFormDefaultValues()
    //         axios.post(APP_URL+ACTION_URL,{'data_for':'get_adhoc_service_details','formData': formData,'user': loggedUser})
    //         .then(res => {
    //             setModalLoading(false);
    //             const fetchedData = res.data;
    //             if( fetchedData.status == 'success' ) {
    //                 setRoster({
    //                     edit_id: fetchedData.service.id,
    //                     service_name: fetchedData.service.service_name,
    //                     mobile_no: fetchedData.service.mobile_no
    //                 })
    //             }

    //             if( fetchedData.status == 'error' ) {
    //                 // setType('add');
    //                 // setPlanID('');
    //                 // toast.error(fetchedData.error, { autoClose: 5000 });
    //             }

    //         })

    //     }
    // },[show])

    const handleSubmitBtn = () => {
        validation.handleSubmit();
    }

    return (
        <>
        { ModalLoading ? <TopProgressBar loading/> : '' }
        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable  s>
            <ModalHeader toggle={onCloseModal} tag="h4">
                {editID ? 'Edit' : 'Add' } {serviceType} details
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col md={12}>
                    <Form>

                    { serviceType == 'email' ?
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Email
                            </Label>
                            <Col sm={9}>
                                    <Input
                                        type="text"
                                        name="service_name"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        value={validation.values.service_name}
                                        invalid={
                                            validation.touched.service_name &&
                                            validation.errors.service_name
                                            ? true
                                            : false
                                        }
                                    />
                                    {validation.touched.service_name &&
                                    validation.errors.service_name ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.service_name}
                                    </FormFeedback>
                                    ) : null}
                            </Col>
                        </Row>
                    : <>
                    <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            {serviceType == 'service' ? 'Service' : 'Supplier'} Name
                            </Label>
                            <Col sm={9}>
                                    <Input
                                        type="text"
                                        name="service_name"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        value={validation.values.service_name}
                                        invalid={
                                            validation.touched.service_name &&
                                            validation.errors.service_name
                                            ? true
                                            : false
                                        }

                                    />
                                    {validation.touched.service_name &&
                                    validation.errors.service_name ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.service_name}
                                    </FormFeedback>
                                    ) : null}
                            </Col>
                        </Row>
                    
                    { serviceType == 'supplier' && 
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Supplier Mobile No
                            </Label>
                            <Col sm={9}>
                                    <Input
                                        type="text"
                                        name="mobile_no"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        value={validation.values.mobile_no}
                                    />
                            </Col>
                        </Row>
                    }

                    </>

                    }

                    </Form>
                    </Col>
                    {/* <Col md={4}>
                        <CardTitle>Activity Log</CardTitle>

                        <div className="order_notes_div">
            
                            <ul className="order_notes">
                                { activityLog.map(activity => (
                                <li key={activity.id} rel={activity.id} className="note">
                                    <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                    <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                    <strong>
                                    {activity.activity_by}
                                    </strong>
                                    </abbr></p>
                                </li>
                                ))}
                            </ul>
                        
                        </div>

                    </Col> */}
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <button type="btn" disabled={ModalLoading} onClick={handleSubmitBtn} className="btn btn-primary w-md">
                    { editID ? 'Update' : 'Add' }
                    </button>
                </div>
            </ModalFooter>
        
    </Modal>

        </> 
    )
}

AdhocServicesFormModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any,
    handleSubmitBtn: PropTypes.func
}

export default AdhocServicesFormModal;