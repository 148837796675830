import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from "react-toastify";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import * as moment from "moment";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Spinners from "components/Common/Spinner";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle,
    ModalFooter
} from "reactstrap";


const ClientCarePlan = ({clientID, clientFname, clientLname, show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [modalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [clientCarePlan, setClientCarePlan] = useState([]);
    const [ndisPlanDate, setNdisPlanDate] = useState('');
    const [dateSigned, setDateSigned] = useState('');
    const [dateCommenced, setDateCommenced] = useState('');
    const [createDate, setCreateDate] = useState('');
    const [Aids, setAids] = useState([])
    const [clientCommunityAccess, setClientCommunityAccess] = useState([]);
    const [type, setType] = useState('add');
    const [loading, setLoading] = useState(false);
    const [planID, setPlanID] = useState('');
    const [activityLog, setActivityLog] = useState([]);



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            initial_review: ( clientCarePlan && clientCarePlan.initial_review) || "",
            ndis_number: ( clientCarePlan && clientCarePlan.ndis_number) || "",
            care_planning_cm: ( clientCarePlan && clientCarePlan.care_planning_cm) || "",
            care_planning_other_first: ( clientCarePlan && clientCarePlan.care_planning_other_first) || "",
            care_planning_other_second: ( clientCarePlan && clientCarePlan.care_planning_other_second) || "",
            care_planning_other_third: ( clientCarePlan && clientCarePlan.care_planning_other_third) || "",
            care_planning_other_fourth: ( clientCarePlan && clientCarePlan.care_planning_other_fourth) || "",
            ndis_plan_goals : ( clientCarePlan && clientCarePlan.ndis_plan_goals) || "",
            care_management_client_goals : ( clientCarePlan && clientCarePlan.care_management_client_goals) || "",
            care_management_actions_required : ( clientCarePlan && clientCarePlan.care_management_actions_required) || "",
            care_management_by_when: ( clientCarePlan && clientCarePlan.care_management_by_when) || "",

            equipments_aids_client_goals : ( clientCarePlan && clientCarePlan.equipments_aids_client_goals) || "",
            equipments_aids_actions_required : ( clientCarePlan && clientCarePlan.equipments_aids_actions_required) || "",
            equipments_aids_by_when: ( clientCarePlan && clientCarePlan.equipments_aids_by_when) || "",

            home_care_client_goals: ( clientCarePlan && clientCarePlan.home_care_client_goals) || "",
            home_care_actions_required: ( clientCarePlan && clientCarePlan.home_care_actions_required) || "",
            home_care_by_when: ( clientCarePlan && clientCarePlan.home_care_by_when) || "",

            personal_care_client_goals: ( clientCarePlan && clientCarePlan.personal_care_client_goals) || "",
            personal_care_actions_required: ( clientCarePlan && clientCarePlan.personal_care_actions_required) || "",
            personal_care_by_when: ( clientCarePlan && clientCarePlan.personal_care_by_when) || "",

            social_support_client_goals: ( clientCarePlan && clientCarePlan.social_support_client_goals) || "",
            social_support_actions_required: ( clientCarePlan && clientCarePlan.social_support_actions_required) || "",
            social_support_by_when: ( clientCarePlan && clientCarePlan.social_support_by_when) || "",

            transport_client_goals: ( clientCarePlan && clientCarePlan.transport_client_goals) || "",
            transport_actions_required: ( clientCarePlan && clientCarePlan.transport_actions_required) || "",
            transport_by_when: ( clientCarePlan && clientCarePlan.transport_by_when) || '',

            allied_health_client_goals: ( clientCarePlan && clientCarePlan.allied_health_client_goals) || '',
            allied_health_action_required: ( clientCarePlan && clientCarePlan.allied_health_action_required) || "",
            allied_health_by_when: ( clientCarePlan && clientCarePlan.allied_health_by_when) || '',

            allied_health_options_first: ( clientCarePlan && clientCarePlan.allied_health_options_first) || "",
            allied_health_options_second: ( clientCarePlan && clientCarePlan.allied_health_options_second) || '',
            allied_health_options_third: ( clientCarePlan && clientCarePlan.allied_health_options_third) || "",
            allied_health_options_fourth: ( clientCarePlan && clientCarePlan.allied_health_options_fourth) || "",

            allied_health_options_more_first: ( clientCarePlan && clientCarePlan.allied_health_options_more_first) || "",
            allied_health_options_more_second: ( clientCarePlan && clientCarePlan.allied_health_options_more_second) || '',
            allied_health_options_more_third: ( clientCarePlan && clientCarePlan.allied_health_options_more_third) || "",
            allied_health_options_more_fourth: ( clientCarePlan && clientCarePlan.allied_health_options_more_fourth) || "",

            is_review: ( clientCarePlan && clientCarePlan.is_review) || "",
            additional_information: ( clientCarePlan && clientCarePlan.additional_info) || "",
        },
        validationSchema: Yup.object({
            // vaccination_flag: Yup.string().required("Required"),
        }),
        onSubmit: values => {
    
            // if( ClientDob == "" ) {
            //   toast.error("Please provide client date of birth", { autoClose: 5000 });
            //   return false;
            // }
    
            const formData = {
                type: type,
                plan_id: clientCarePlan.id,
                user_id: clientID,
                create_date: createDate,
                ndis_number: values.ndis_number,
                initial_review: values.initial_review,
                ndis_number: values.ndis_number,
                care_planning_cm: values.care_planning_cm,
                care_planning_other_first: values.care_planning_other_first,
                care_planning_other_second: values.care_planning_other_second,
                care_planning_other_third: values.care_planning_other_third,
                care_planning_other_fourth: values.care_planning_other_fourth,
                ndis_plan_goals : values.ndis_plan_goals,
                care_management_client_goals : values.care_management_client_goals,
                care_management_actions_required : values.care_management_actions_required,
                care_management_by_when: values.care_management_by_when,

                equipments_aids_client_goals : values.equipments_aids_client_goals,
                equipments_aids_actions_required : values.equipments_aids_actions_required,
                equipments_aids_by_when: values.equipments_aids_by_when,

                home_care_client_goals: values.home_care_client_goals,
                home_care_actions_required: values.home_care_actions_required,
                home_care_by_when: values.home_care_by_when,

                personal_care_client_goals: values.personal_care_client_goals,
                personal_care_actions_required: values.personal_care_actions_required,
                personal_care_by_when: values.personal_care_by_when,

                social_support_client_goals: values.social_support_client_goals,
                social_support_actions_required: values.social_support_actions_required,
                social_support_by_when: values.social_support_by_when,

                transport_client_goals: values.transport_client_goals,
                transport_actions_required: values.transport_actions_required,
                transport_by_when: values.transport_by_when,

                allied_health_client_goals: values.allied_health_client_goals,
                allied_health_action_required: values.allied_health_action_required,
                allied_health_by_when: values.allied_health_by_when,

                allied_health_options_first: values.allied_health_options_first,
                allied_health_options_second: values.allied_health_options_second,
                allied_health_options_third: values.allied_health_options_third,
                allied_health_options_fourth: values.allied_health_options_fourth,

                allied_health_options_more_first: values.allied_health_options_more_first,
                allied_health_options_more_second: values.allied_health_options_more_second,
                allied_health_options_more_third: values.allied_health_options_more_third,
                allied_health_options_more_fourth: values.allied_health_options_more_fourth,

                is_review: values.is_review,
                additional_information: values.additional_information,
                 
            }
    
            console.log( formData);

            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'update_client_care_plan','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {
                    // setType('edit');
                    setActivityLog(activityLog => [fetchedData.activity_log,...activityLog]);
                    toast.success(fetchedData.html, { autoClose: 5000 });
                }
    
                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.html, { autoClose: 5000 });
                }
    
            })
    
        }
    })

    useEffect(() => {

        if( show ) {

            const formData = {
                uid: clientID
            }
            setModalLoading(true)
            axios.post(APP_URL+ACTION_URL,{'data_for':'client_care_plan','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {

                    setType('edit');
                    setClientCarePlan(fetchedData.data);
                    setCreateDate(fetchedData.data.create_date);
                    setActivityLog(fetchedData.activity_log);
                    toast.success(fetchedData.html, { autoClose: 5000 });
                }

                if( fetchedData.status == 'error' ) {
                    setType('add');
                    setPlanID('');
                    // toast.error(fetchedData.error, { autoClose: 5000 });
                }

            })

        }
    },[show])

    const handleSubmitBtn = () => {
        validation.handleSubmit();
    }

    return (
        <>

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable  s>
            <ModalHeader toggle={onCloseModal} tag="h4">
                Client Care Plan{ modalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col md={8}>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label>Client First Name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // onChange={validation.handleChange}
                                        value={validation.values.client_fname || clientFname}
                                        readonly
                                    />
                                </div>
                                </Col>
                                <Col md={6}>
                                <div className="mb-3">
                                    <Label>Client Last Name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // onChange={validation.handleChange}
                                        value={validation.values.client_lname || clientLname}
                                        readonly
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label>Date Created</Label>
                                    <InputGroup>
                                    <Flatpickr
                                        name="create_date"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setCreateDate(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.create_date || createDate}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                    </InputGroup>
                                </div>
                            </Col>    
                        </Row>
                        <br /> <br/><CardTitle>Client Information</CardTitle>
                        <Col lg={8}>
                            <div className="mb-3">
                                {/* <Label>First Name</Label> */}
                                <Input
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value="initial"
                                    name="initial_review"
                                    checked={validation.values.initial_review == 'initial' ? true : false}
                                /> &nbsp;Initial
                                &nbsp;&nbsp;

                                <Input
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value="review"
                                    name="initial_review"
                                    checked={validation.values.initial_review == 'review' ? true : false}
                                /> &nbsp;Review
                                &nbsp;&nbsp;
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label>NDIS Number</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.ndis_number}
                                    name="ndis_number"
                                />
                            </div>
                        </Col>

                        <br /> <br/><CardTitle>Persons Involved in CarePlaning</CardTitle>

                        <Row>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Case Manager</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.care_planning_cm}
                                    name="care_planning_cm"
                                />
                            </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                <Label>Other</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.care_planning_other_first}
                                    name="care_planning_other_first"
                                />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                <Label>Other</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.care_planning_other_second}
                                    name="care_planning_other_second"
                                />
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className="mb-3">
                                <Label>Other</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.care_planning_other_third}
                                    name="care_planning_other_third"
                                />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                <Label>Other</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.care_planning_other_fourth}
                                    name="care_planning_other_fourth"
                                />
                                </div>
                            </Col>
                        </Row>
                        <br/><br/>

                        <CardTitle>This Care Plan was prepared after reviewing the NDIS Plan and Goals</CardTitle>

                        <Row>
                            <Col lg={12}>
                                <label>Updated Notes as a result of changes since preparation of NDIS Plan</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="ndis_plan_goals"
                                    value={validation.values.ndis_plan_goals}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.ndis_plan_goals.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.ndis_plan_goals.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br/><br/>
                        <CardTitle>Support Available</CardTitle>
                        <p>This Care Plan has one overriding goal and that is to ensure that every client can remain living in their own home in a safe and happy environment. We expect some individual care plan goals will change over time.</p>
                        <p>However, all actions and decisions under this NDIS Plan will reflect the overriding goal of ensuring the client remains living in their own home in a safe and happy environment.</p>
                        

                        <br/> <br/><CardTitle>1.Access to Care Management</CardTitle>
                        <em>To Support myself in the management of my NDIS Plan</em>

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="care_management_client_goals"
                                value={validation.values.care_management_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.care_management_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.care_management_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="care_management_actions_required"
                                value={validation.values.care_management_actions_required}
                                rows="3"
                                />
                                {validation.values.care_management_actions_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.care_management_actions_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="care_management_by_when"
                                value={validation.values.care_management_by_when}
                                rows="3"
                                />
                                {validation.values.care_management_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.care_management_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>

                        <br/> <br/><CardTitle>2. Equipment and Aids</CardTitle>
                        <em>So myself, family and support staff can attend to my care needs ensuring the risk of injury is reduced.</em>

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="equipments_aids_client_goals"
                                value={validation.values.equipments_aids_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.equipments_aids_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.equipments_aids_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="equipments_aids_actions_required"
                                value={validation.values.equipments_aids_actions_required}
                                rows="3"
                                />
                                {validation.values.equipments_aids_actions_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.equipments_aids_actions_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="equipments_aids_by_when"
                                value={validation.values.equipments_aids_by_when}
                                rows="3"
                                />
                                {validation.values.equipments_aids_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.equipments_aids_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>
                        
                        <br/> <br/><CardTitle>3. Home Care</CardTitle>
                        <em>Assistance to attend to home care domestic tasks such as cleaning.</em>

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="home_care_client_goals"
                                value={validation.values.home_care_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.home_care_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.home_care_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="home_care_actions_required"
                                value={validation.values.home_care_actions_required}
                                rows="3"
                                />
                                {validation.values.home_care_actions_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.home_care_actions_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="home_care_by_when"
                                value={validation.values.home_care_by_when}
                                rows="3"
                                />
                                {validation.values.home_care_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.home_care_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>


                        <br/> <br/><CardTitle>4. Personal Care</CardTitle>
                        <em>Assistance to attend to my own personal care and meal preparation.</em>

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="personal_care_client_goals"
                                value={validation.values.personal_care_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.personal_care_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.personal_care_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="personal_care_actions_required"
                                value={validation.values.personal_care_actions_required}
                                rows="3"
                                />
                                {validation.values.personal_care_actions_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.personal_care_actions_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="personal_care_by_when"
                                value={validation.values.personal_care_by_when}
                                rows="3"
                                />
                                {validation.values.personal_care_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.personal_care_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>

                        <br/> <br/><CardTitle>5. Social Support</CardTitle>
                        

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="social_support_client_goals"
                                value={validation.values.social_support_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.social_support_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.social_support_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="social_support_actions_required"
                                value={validation.values.social_support_actions_required}
                                rows="3"
                                />
                                {validation.values.social_support_actions_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.social_support_actions_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="social_support_by_when"
                                value={validation.values.social_support_by_when}
                                rows="3"
                                />
                                {validation.values.social_support_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.social_support_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>


                        <br/> <br/><CardTitle>6. Transport</CardTitle>
                        <em>Access to transport services for medical appointments and shopping.</em>

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="transport_client_goals"
                                value={validation.values.transport_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.transport_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.transport_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="transport_actions_required"
                                value={validation.values.transport_actions_required}
                                rows="3"
                                />
                                {validation.values.transport_actions_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.transport_actions_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="transport_by_when"
                                value={validation.values.transport_by_when}
                                rows="3"
                                />
                                {validation.values.transport_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.transport_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>


                        <br/> <br/><CardTitle>7. Allied Health</CardTitle>
                        <em>Access to required medical nursing and allied health services including podiatry, OT and physio.</em>

                        <Row>
                            <Col lg={4}>
                            <label>Client Goals</label>
                            <Input
                                type="textarea"
                                name="allied_health_client_goals"
                                value={validation.values.allied_health_client_goals}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.allied_health_client_goals.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_client_goals.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>Actions Required</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_action_required"
                                value={validation.values.allied_health_action_required}
                                rows="3"
                                />
                                {validation.values.allied_health_action_required.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_action_required.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>By When</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_by_when"
                                value={validation.values.allied_health_by_when}
                                rows="3"
                                />
                                {validation.values.allied_health_by_when.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_by_when.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>

                        <br/> <br/><CardTitle>8.</CardTitle>
                        
                        <Row>
                            <Col lg={6}>
                            <label>1.</label>
                            <Input
                                type="textarea"
                                name="allied_health_options_first"
                                value={validation.values.allied_health_options_first}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.allied_health_options_first.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_first.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={6}>
                            <label>2.</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_options_second"
                                value={validation.values.allied_health_options_second}
                                rows="3"
                                />
                                {validation.values.allied_health_options_second.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_second.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={6}>
                            <label>3.</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_options_third"
                                value={validation.values.allied_health_options_third}
                                rows="3"
                                />
                                {validation.values.allied_health_options_third.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_third.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={6}>
                            <label>4.</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_options_fourth"
                                value={validation.values.allied_health_options_fourth}
                                rows="3"
                                />
                                {validation.values.allied_health_options_fourth.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_fourth.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>


                        <br/> <br/><CardTitle>9. </CardTitle>
                        

                        <Row>
                            <Col lg={6}>
                            <label>1.</label>
                            <Input
                                type="textarea"
                                name="allied_health_options_more_first"
                                value={validation.values.allied_health_options_more_first}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.allied_health_options_more_first.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_more_first.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={6}>
                            <label>2.</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_options_more_second"
                                value={validation.values.allied_health_options_more_second}
                                rows="3"
                                />
                                {validation.values.allied_health_options_more_second.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_more_second.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={6}>
                            <label>3.</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_options_more_third"
                                value={validation.values.allied_health_options_more_third}
                                rows="3"
                                />
                                {validation.values.allied_health_options_more_third.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_more_third.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={6}>
                            <label>4.</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="allied_health_options_more_fourth"
                                value={validation.values.allied_health_options_more_fourth}
                                rows="3"
                                />
                                {validation.values.allied_health_options_more_fourth.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.allied_health_options_more_fourth.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>

                        <br/><br/>
                        <CardTitle>If this is a review, were previous goals achieved and what actions are required?</CardTitle>

                        <Row>
                            <Col lg={12}>
                                
                                <Input
                                    type="textarea"
                                    name="is_review"
                                    value={validation.values.is_review}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.is_review.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.is_review.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br/>

                        <Row>
                            <Col lg={12}>
                                <label>Additional Information</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="additional_information"
                                    value={validation.values.additional_information}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.additional_information.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.additional_information.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        </Form>
                    </Col>
                    <Col md={4}>
                        <CardTitle>Activity Log</CardTitle>

                        <div className="order_notes_div">
            
                            <ul className="order_notes">
                                { activityLog.map(activity => (
                                <li key={activity.id} rel={activity.id} className="note">
                                    <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                    <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                    <strong>
                                    {activity.activity_by}
                                    </strong>
                                    </abbr></p>
                                </li>
                                ))}
                            </ul>
                        
                        </div>

                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <button type="btn" disabled={modalLoading} onClick={handleSubmitBtn} className="btn btn-primary w-md">
                    { type == 'edit' ? 'Update' : 'Add' }
                    </button>
                </div>
            </ModalFooter>
        
    </Modal>

        </> 
    )

}

ClientCarePlan.propTypes = {
        onCloseModal: PropTypes.func,
        show: PropTypes.any,
        handleSubmitBtn: PropTypes.func
    }

export default ClientCarePlan;